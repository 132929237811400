import React, { useEffect } from 'react';
import './remarketing.css';
import logo from '../../imagens/logo_menu_login.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { isAuthenticated, logout } from '../../auth/auth';
import ChatRemarketing from './ChatRemarketing'; // Importando o Chat de Remarketing

const RemarketingStrategy = () => {
  const userEmail = localStorage.getItem('userEmail');
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login'); // Redireciona o usuário não autenticado para a tela de login
    }
  }, [navigate]);

  return (
    <div className="remarketing-strategy-container">
      <div className="remarketing-strategy-header">
        <img src={logo} alt="Logo" className="remarketing-strategy-logo" />
        <div className="remarketing-strategy-user-info">
          <span>{userEmail}</span>
          <button className="remarketing-strategy-button" onClick={logout}>Sair</button>
        </div>
      </div>
      <div className="remarketing-strategy-content">
        <h1 className="remarketing-strategy-title-gradient">Estratégia de Remarketing</h1>
        <p className="remarketing-strategy-description">
          🔁 <strong>Potencialize Suas Vendas com a Estratégia de Remarketing!</strong> <br /><br />
          Nesta página, você vai utilizar nossa Inteligência Artificial para criar <strong>estratégias de remarketing</strong> junto com <strong>cópias persuasivas</strong> para reengajar clientes e impulsionar suas vendas. Com textos otimizados e personalizados, sua marca vai se manter presente e relevante para os clientes que já demonstraram interesse no seu produto ou serviço. <br /><br />
          📈 <strong>Aumente suas chances de conversão!</strong> Aproveite nossa IA para criar mensagens que atraem seus clientes de volta e geram novas oportunidades de fechar negócios. Mais do que simples textos, você está construindo uma estratégia sólida para <strong>prosperar na internet</strong>.
        </p>

        <Link to="/ferramentas" className="remarketing-strategy-button-menu" style={{ textDecoration: 'none' }}>
          <FontAwesomeIcon icon={faArrowLeft} /> Voltar para o Menu
        </Link>

        <br /> {/* Adiciona uma linha de espaçamento */}
        <br /> {/* Adiciona mais uma linha de espaçamento */}

        {/* Chat de Remarketing renderizado abaixo do botão de voltar para o menu */}
        <ChatRemarketing />
      </div>
    </div>
  );
};

export default RemarketingStrategy;
