// src/components/FacebookAdsDashboard/FacebookAdsDashboard.js
import React, { useEffect, useState } from 'react';
import logo from '../../imagens/logo_menu_login.webp';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated, logout, getUserName } from '../../auth/auth';
import './AnaliseMetricas.css';
import {
  FaArrowLeft,
  FaBullhorn,
  FaCopy,
  FaSyncAlt,
  FaCalendarAlt,
} from 'react-icons/fa';
import FacebookLogin from './FacebookLogin';
import axios from 'axios';
import qs from 'qs';
import { motion } from 'framer-motion';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCachedData, setCachedData } from './cache';
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';

// Importar configurações de métricas
import {
  metricNames,
  defaultMetrics,
  sumMetrics,
  averageMetrics,
  metricOrder,
} from './MetricsConfig';

// Definições de campaignObjectives e destinationTypes
const campaignObjectives = {
  OUTCOME_ENGAGEMENT: 'Engajamento',
  OUTCOME_SALES: 'Vendas',
  OUTCOME_LEAD_GENERATION: 'Geração de Leads',
  // Adicione outras traduções conforme necessário
};

const destinationTypes = {
  WHATSAPP: 'WhatsApp',
  WEBSITE: 'Site',
  MOBILE_APP: 'Aplicativo Móvel',
  // Adicione outras traduções conforme necessário
};

// Novo Componente para Seleção de Cores dentro do Modal de Seleção de Métricas
const MetricColorSelector = ({ metric, selectedMetricsColors, setSelectedMetricsColors }) => {
  const colors = ['#22c55e', '#3b82f6', '#ef4444']; // Verde, Azul, Vermelho

  const handleColorClick = (color) => {
    const currentColor = selectedMetricsColors[metric];
    let newColors = { ...selectedMetricsColors };

    if (currentColor === color) {
      // Desativar a cor se já estiver selecionada
      delete newColors[metric];
    } else {
      // Definir a nova cor
      newColors[metric] = color;
    }

    setSelectedMetricsColors(newColors);
    localStorage.setItem('selectedMetricsColors', JSON.stringify(newColors));
  };

  return (
    <div className="metric-color-selector">
      {colors.map((color) => (
        <span
          key={color}
          className={`color-circle ${selectedMetricsColors[metric] === color ? 'selected-color' : ''}`}
          style={{ backgroundColor: color }}
          onClick={() => handleColorClick(color)}
          title={`Selecionar ${metricNames[metric]}`}
        />
      ))}
    </div>
  );
};

const FacebookAdsDashboard = () => {
  const navigate = useNavigate();
  const userEmail = localStorage.getItem('userEmail') || '';

  // Estado para o token do Facebook
  const [facebookToken, setFacebookToken] = useState(
    localStorage.getItem(`facebookToken_${userEmail}`) ||
    sessionStorage.getItem(`facebookToken_${userEmail}`) ||
    null
  );

  // Estados gerais
  const [accounts, setAccounts] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [adSets, setAdSets] = useState([]);
  const [ads, setAds] = useState([]);
  const [metrics, setMetrics] = useState({});
  const [totalMetrics, setTotalMetrics] = useState({});
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedItems, setSelectedItems] = useState({
    campaigns: [],
    adSets: [],
    ads: [],
  });
  const [currentLevel, setCurrentLevel] = useState('campaigns');
  const [dateRange, setDateRange] = useState({ since: '', until: '' });
  const [datePreset, setDatePreset] = useState('');
  const [loading, setLoading] = useState(false);
  const [metricsFetched, setMetricsFetched] = useState(false);
  const [selectedMetrics, setSelectedMetrics] = useState([]); // Inicializar vazio
  const [showMetricsSelector, setShowMetricsSelector] = useState(false);
  const [showDateSelector, setShowDateSelector] = useState(false);
  const [error, setError] = useState(null);
  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const [statusFilter, setStatusFilter] = useState('Qualquer');
  const [nameFilter, setNameFilter] = useState('');
  const [columnWidths, setColumnWidths] = useState({});
  const [isResizing, setIsResizing] = useState(false);
  const [currentMetric, setCurrentMetric] = useState(null);
  
  // Estado para cores das métricas
  const [selectedMetricsColors, setSelectedMetricsColors] = useState(() => {
    const savedColors = localStorage.getItem('selectedMetricsColors');
    return savedColors ? JSON.parse(savedColors) : {};
  });

  // Inicializar selectedMetrics com todas as métricas disponíveis ou a partir do localStorage
  useEffect(() => {
    const savedSelectedMetrics = localStorage.getItem(`selectedMetrics_${userEmail}`);
    if (savedSelectedMetrics) {
      setSelectedMetrics(JSON.parse(savedSelectedMetrics));
    } else {
      setSelectedMetrics(defaultMetrics); // Use defaultMetrics para iniciar com todas as métricas
    }
  }, [userEmail]);

  // Atualização das métricas relacionadas à autenticação
  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login');
    }
  }, [navigate]);

  // Atualização de estado e verificação de autenticação
  useEffect(() => {
    if (isAuthenticated()) {
      setLoading(true);
      // Outras inicializações podem ser colocadas aqui
      setLoading(false);
    } else {
      navigate('/login');
    }
  }, [navigate]);

  // Configurar interceptadores do Axios para tratamento de erros
  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response) {
          const { status, data } = error.response;
          if (status === 401 || (data && data.error && data.error.code === 190)) {
            // Código 190 geralmente indica erro de autenticação no Facebook
            toast.error('Sessão expirada. Por favor, faça login novamente.', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            handleLogout();
          } else if (status === 400) {
            toast.error('Por favor, aguarde um momento e atualize a página.', {
              position: "top-right",
              autoClose: 5000,
            });
          } else {
            toast.error('Ocorreu um erro inesperado.', {
              position: "top-right",
              autoClose: 5000,
            });
          }
        } else if (error.request) {
          toast.error('Erro na conexão com o servidor.', {
            position: "top-right",
            autoClose: 5000,
          });
        } else {
          toast.error('Ocorreu um erro: ' + error.message, {
            position: "top-right",
            autoClose: 5000,
          });
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  useEffect(() => {
    // Carrega a data salva no localStorage
    const savedDateRange = localStorage.getItem(`dateRange_${userEmail}`);
    const savedDatePreset = localStorage.getItem(`datePreset_${userEmail}`);
    if (savedDateRange) {
      setDateRange(JSON.parse(savedDateRange));
    } else {
      // Se não houver data salva, usar a data atual do navegador
      const today = new Date();
      const formattedDate = today.toISOString().split('T')[0];
      setDateRange({ since: formattedDate, until: formattedDate });
      localStorage.setItem(`dateRange_${userEmail}`, JSON.stringify({ since: formattedDate, until: formattedDate }));
    }
    if (savedDatePreset) {
      setDatePreset(savedDatePreset);
    }

    const savedAccount = localStorage.getItem('selectedAccount');
    if (savedAccount) {
      setSelectedAccount(savedAccount);
    }

    // Intervalo para verificar atualizações no token
    const intervalId = setInterval(() => {
      const token =
        localStorage.getItem(`facebookToken_${userEmail}`) ||
        sessionStorage.getItem(`facebookToken_${userEmail}`);
      if (token !== facebookToken) {
        setFacebookToken(token);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [userEmail, facebookToken]);

  useEffect(() => {
    if (facebookToken) {
      fetchAdAccounts(facebookToken);
      setError(null); // Limpa qualquer erro anterior
    } else {
      setError(null);
      setAccounts([]);
      // Exibe notificação para login no Facebook
      toast.info('Por favor, faça login no Facebook para acessar as métricas.', {
        position: "top-right",
        autoClose: 5000,
      });
    }
  }, [facebookToken, userEmail]);

  // Funções para buscar dados do Facebook Ads
  const fetchAdAccounts = async (token = null) => {
    setLoading(true);
    const loadingToastId = toast.loading("Carregando contas de anúncio...");
    try {
      const accessToken =
        token ||
        localStorage.getItem(`facebookToken_${userEmail}`) ||
        sessionStorage.getItem(`facebookToken_${userEmail}`);
      if (!accessToken) {
        setAccounts([]);
        toast.update(loadingToastId, { render: "Token de acesso não encontrado.", type: "error", isLoading: false, autoClose: 5000 });
        return;
      }

      // Verificar cache
      const cachedAccounts = getCachedData(`adAccounts_${userEmail}`);
      if (cachedAccounts) {
        setAccounts(cachedAccounts);
        toast.update(loadingToastId, { render: "Contas de anúncio carregadas do cache.", type: "success", isLoading: false, autoClose: 3000 });
        return;
      }

      const response = await axios.get(
        'https://graph.facebook.com/v21.0/me/adaccounts',
        {
          params: {
            access_token: accessToken,
            fields: 'id,name',
          },
        }
      );
      setAccounts(response.data.data);
      setCachedData(`adAccounts_${userEmail}`, response.data.data);

      const savedAccount = localStorage.getItem('selectedAccount');
      if (
        savedAccount &&
        response.data.data.some((acc) => acc.id === savedAccount)
      ) {
        setSelectedAccount(savedAccount);
        await fetchCampaigns(savedAccount);
      } else if (response.data.data.length > 0) {
        const firstAccount = response.data.data[0];
        setSelectedAccount(firstAccount.id);
        localStorage.setItem('selectedAccount', firstAccount.id); // Garantir que está salvo
        await fetchCampaigns(firstAccount.id);
      }

      toast.update(loadingToastId, { render: "Contas de anúncio carregadas com sucesso.", type: "success", isLoading: false, autoClose: 3000 });
    } catch (error) {
      // Erro será tratado pelo interceptor do Axios
      console.error('Erro ao buscar contas de anúncio:', error);
      toast.update(loadingToastId, { render: "Erro ao carregar contas de anúncio.", type: "error", isLoading: false, autoClose: 5000 });
    } finally {
      setLoading(false);
    }
  };

  const fetchCampaigns = async (accountId) => {
    setSelectedAccount(accountId);
    localStorage.setItem('selectedAccount', accountId);
    setCampaigns([]);
    setAdSets([]);
    setAds([]);
    setMetrics({});
    setCurrentLevel('campaigns');
    setSelectedItems({ campaigns: [], adSets: [], ads: [] });
    setLoading(true);
    const loadingToastId = toast.loading("Carregando campanhas...");
    try {
      // Verificar cache
      const cachedCampaigns = getCachedData(`campaigns_${accountId}_${userEmail}`);
      if (cachedCampaigns) {
        setCampaigns(cachedCampaigns);
        toast.update(loadingToastId, { render: "Campanhas carregadas do cache.", type: "success", isLoading: false, autoClose: 3000 });
        // Carregar métricas automaticamente
        await fetchMetrics(cachedCampaigns, 'campaigns');
        return;
      }

      const accessToken =
        facebookToken ||
        localStorage.getItem(`facebookToken_${userEmail}`) ||
        sessionStorage.getItem(`facebookToken_${userEmail}`);
      const response = await axios.get(
        `https://graph.facebook.com/v21.0/${accountId}/campaigns`,
        {
          params: {
            access_token: accessToken,
            fields:
              'id,name,objective,buying_type,is_campaign_budget_optimization,status',
          },
        }
      );
      setCampaigns(response.data.data);
      setCachedData(`campaigns_${accountId}_${userEmail}`, response.data.data);

      // Carregar métricas automaticamente
      await fetchMetrics(response.data.data, 'campaigns');
      
      toast.update(loadingToastId, { render: "Campanhas carregadas com sucesso.", type: "success", isLoading: false, autoClose: 3000 });
    } catch (error) {
      // Erro será tratado pelo interceptor do Axios
      console.error('Erro ao buscar campanhas:', error);
      toast.update(loadingToastId, { render: "Erro ao carregar campanhas.", type: "error", isLoading: false, autoClose: 5000 });
    } finally {
      setLoading(false);
    }
  };

  const fetchAdSets = async (campaignIds) => {
    setAdSets([]);
    setAds([]);
    setMetrics({});
    setLoading(true);
    const loadingToastId = toast.loading("Carregando conjuntos de anúncios...");
    try {
      const accessToken =
        facebookToken ||
        localStorage.getItem(`facebookToken_${userEmail}`) ||
        sessionStorage.getItem(`facebookToken_${userEmail}`);
      if (!accessToken) {
        setError('Token de acesso não encontrado.');
        toast.update(loadingToastId, { render: "Token de acesso não encontrado.", type: "error", isLoading: false, autoClose: 5000 });
        return;
      }

      let allAdSets = [];
      let ids = Array.isArray(campaignIds) ? campaignIds : [campaignIds];

      if (ids.length === 0) {
        // Se nenhum ID for fornecido, buscar de todas as campanhas
        ids = campaigns.map((campaign) => campaign.id);
      }

      // Filtrar IDs que já estão em cache
      const adSetPromises = ids.map(async (campaignId) => {
        const cachedAdSets = getCachedData(`adSets_${campaignId}_${userEmail}`);
        if (cachedAdSets) {
          return cachedAdSets;
        } else {
          const response = await axios.get(
            `https://graph.facebook.com/v21.0/${campaignId}/adsets`,
            {
              params: {
                access_token: accessToken,
                fields:
                  'id,name,optimization_goal,promoted_object,destination_type,daily_budget,lifetime_budget,status,campaign_id',
              },
            }
          );
          setCachedData(`adSets_${campaignId}_${userEmail}`, response.data.data);
          return response.data.data;
        }
      });

      const results = await Promise.allSettled(adSetPromises);
      results.forEach(result => {
        if (result.status === 'fulfilled') {
          allAdSets = allAdSets.concat(result.value);
        } else {
          console.error('Erro ao buscar conjuntos de anúncios:', result.reason);
          toast.error('Erro ao carregar alguns conjuntos de anúncios.', {
            position: "top-right",
            autoClose: 5000,
          });
        }
      });

      setAdSets(allAdSets);

      // Carregar métricas automaticamente
      await fetchMetrics(allAdSets, 'adSets');

      toast.update(loadingToastId, { render: "Conjuntos de anúncios carregados com sucesso.", type: "success", isLoading: false, autoClose: 3000 });
    } catch (error) {
      // Erro será tratado pelo interceptor do Axios
      console.error('Erro ao buscar conjuntos de anúncios:', error);
      toast.update(loadingToastId, { render: "Erro ao carregar conjuntos de anúncios.", type: "error", isLoading: false, autoClose: 5000 });
    } finally {
      setLoading(false);
    }
  };

  const fetchAds = async (adSetIds) => {
    setAds([]);
    setMetrics({});
    setLoading(true);
    const loadingToastId = toast.loading("Carregando anúncios...");
    try {
      const accessToken =
        facebookToken ||
        localStorage.getItem(`facebookToken_${userEmail}`) ||
        sessionStorage.getItem(`facebookToken_${userEmail}`);
      if (!accessToken) {
        setError('Token de acesso não encontrado.');
        toast.update(loadingToastId, { render: "Token de acesso não encontrado.", type: "error", isLoading: false, autoClose: 5000 });
        return;
      }

      let allAds = [];
      let ids = Array.isArray(adSetIds) ? adSetIds : [adSetIds];

      if (ids.length === 0) {
        // Se nenhum ID for fornecido, buscar de todos os conjuntos de anúncios
        ids = adSets.map((adSet) => adSet.id);
      }

      // Filtrar IDs que já estão em cache
      const adPromises = ids.map(async (adSetId) => {
        const cachedAds = getCachedData(`ads_${adSetId}_${userEmail}`);
        if (cachedAds) {
          return cachedAds;
        } else {
          const response = await axios.get(
            `https://graph.facebook.com/v21.0/${adSetId}/ads`,
            {
              params: {
                access_token: accessToken,
                fields:
                  'id,name,creative{object_story_spec},status,adset_id,campaign_id',
              },
            }
          );
          const adsWithCreativeType = response.data.data.map((ad) => ({
            ...ad,
            creative_type: getCreativeType(ad.creative?.object_story_spec),
          }));
          setCachedData(`ads_${adSetId}_${userEmail}`, adsWithCreativeType);
          return adsWithCreativeType;
        }
      });

      const results = await Promise.allSettled(adPromises);
      results.forEach(result => {
        if (result.status === 'fulfilled') {
          allAds = allAds.concat(result.value);
        } else {
          console.error('Erro ao buscar anúncios:', result.reason);
          toast.error('Erro ao carregar alguns anúncios.', {
            position: "top-right",
            autoClose: 5000,
          });
        }
      });

      setAds(allAds);

      // Carregar métricas automaticamente
      await fetchMetrics(allAds, 'ads');

      toast.update(loadingToastId, { render: "Anúncios carregados com sucesso.", type: "success", isLoading: false, autoClose: 3000 });
    } catch (error) {
      // Erro será tratado pelo interceptor do Axios
      console.error('Erro ao buscar anúncios:', error);
      toast.update(loadingToastId, { render: "Erro ao carregar anúncios.", type: "error", isLoading: false, autoClose: 5000 });
    } finally {
      setLoading(false);
    }
  };

  // Função para buscar métricas incrementalmente
  const fetchMetrics = async (items, level) => {
    setMetrics({});
    setMetricsFetched(false);
    setLoading(true);
    const loadingToastId = toast.loading("Carregando métricas...");
    try {
      const accessToken =
        facebookToken ||
        localStorage.getItem(`facebookToken_${userEmail}`) ||
        sessionStorage.getItem(`facebookToken_${userEmail}`);
      if (!accessToken) {
        setError('Token de acesso não encontrado.');
        toast.update(loadingToastId, { render: "Token de acesso não encontrado.", type: "error", isLoading: false, autoClose: 5000 });
        return;
      }

      const levelMapping = {
        campaigns: 'campaign',
        adSets: 'adset',
        ads: 'ad',
      };

      let totals = {};
      let counts = {};

      selectedMetrics.forEach((metric) => {
        totals[metric] = 0;
        counts[metric] = 0;
      });

      const fetchPromises = items.map(async (item) => {
        const params = {
          access_token: accessToken,
          fields:
            'impressions,reach,frequency,spend,cpm,inline_link_click_ctr,inline_link_clicks,cost_per_inline_link_click,actions,cost_per_action_type,video_p75_watched_actions,video_thruplay_watched_actions',
          time_increment: 1,
          level: levelMapping[level],
        };

        if (datePreset) {
          params.date_preset = datePreset;
        } else if (dateRange.since && dateRange.until) {
          params.time_range = {
            since: dateRange.since,
            until: dateRange.until,
          };
        } else {
          // Se nenhuma data for selecionada, usar os últimos 7 dias
          params.date_preset = 'last_7d';
        }

        try {
          const response = await axios.get(
            `https://graph.facebook.com/v21.0/${item.id}/insights`,
            {
              params: params,
              paramsSerializer: (params) =>
                qs.stringify(params, { arrayFormat: 'comma' }),
            }
          );

          const data = response.data.data;
          let itemMetrics = {};

          if (data && data.length > 0) {
            const metricData = data[0];

            const roiValue = metricData.action_values
              ? metricData.action_values.find(
                  (action) => action.action_type === 'omni_purchase'
                )?.value
              : null;
            const roi = roiValue ? parseFloat(roiValue).toFixed(2) : '0.00';

            const totalResults = metricData.actions
              ? metricData.actions
                  .filter((action) => action.action_type === 'onsite_conversion.messaging_conversation_started_7d')
                  .reduce((sum, action) => sum + parseInt(action.value, 10), 0)
              : 0;

            const costPerResultAction = metricData.cost_per_action_type
              ? metricData.cost_per_action_type.find(
                  (action) => action.action_type === 'onsite_conversion.messaging_conversation_started_7d'
                )
              : null;

            const costPerResult =
              costPerResultAction && costPerResultAction.value
                ? parseFloat(
                    costPerResultAction.value
                      .replace('R$', '')
                      .replace(',', '.') || '0.00'
                  )
                : 0.0;

            const video3SecViewsAction = metricData.actions
              ? metricData.actions.find(
                  (action) => action.action_type === 'video_view'
                )
              : null;
            const video3SecViews = video3SecViewsAction
              ? parseInt(video3SecViewsAction.value, 10)
              : 0;

            const video75ViewsAction = metricData.video_p75_watched_actions
              ? metricData.video_p75_watched_actions[0]
              : null;
            const video75Views = video75ViewsAction
              ? parseInt(video75ViewsAction.value, 10)
              : 0;

            const hookRate =
              metricData.impressions && video3SecViews
                ? (video3SecViews / parseInt(metricData.impressions, 10)) * 100
                : 0;

            const holdRate =
              metricData.impressions && video75Views
                ? (video75Views / parseInt(metricData.impressions, 10)) * 100
                : 0;

            const frequency = parseFloat(metricData.frequency) || 0;

            itemMetrics = {
              ...metricData,
              roi: parseFloat(roi),
              totalResults: totalResults,
              costPerResult: costPerResult,
              link_click_ctr: parseFloat(metricData.inline_link_click_ctr) || 0,
              link_click_cpc: metricData.cost_per_inline_link_click
                ? parseFloat(
                    metricData.cost_per_inline_link_click
                      .replace('R$', '')
                      .replace(',', '.') || '0.00'
                  )
                : 0.0,
              video3SecViews: video3SecViews,
              video75Views: video75Views,
              hookRate: parseFloat(hookRate.toFixed(2)),
              holdRate: parseFloat(holdRate.toFixed(2)),
              cpm: parseFloat(metricData.cpm) || 0,
              frequency: frequency,
            };
          } else {
            itemMetrics = {
              roi: 0,
              totalResults: 0,
              costPerResult: 0,
              link_click_ctr: 0,
              link_click_cpc: 0,
              video3SecViews: 0,
              video75Views: 0,
              hookRate: 0,
              holdRate: 0,
              frequency: 0,
              impressions: 0,
              reach: 0,
              spend: 0,
              cpm: 0,
              inline_link_clicks: 0,
            };
          }

          // Atualiza o estado das métricas incrementalmente
          setMetrics((prevMetrics) => ({
            ...prevMetrics,
            [item.id]: itemMetrics,
          }));

          // Atualiza os totais
          selectedMetrics.forEach((metric) => {
            const value = parseFloat(itemMetrics[metric]);
            if (!isNaN(value)) {
              if (sumMetrics.includes(metric)) {
                totals[metric] += value;
              } else if (averageMetrics.includes(metric)) {
                totals[metric] += value;
                counts[metric] += 1;
              }
            }
          });
        } catch (error) {
          console.error(`Erro ao buscar métricas para ${item.id}:`, error);
          toast.error(`Erro ao carregar métricas para ${item.name}.`, {
            position: "top-right",
            autoClose: 5000,
          });
        }
      });

      await Promise.allSettled(fetchPromises); // Permitir que algumas requisições falhem

      // Calculando médias após a soma total
      averageMetrics.forEach((metric) => {
        if (counts[metric] > 0) {
          totals[metric] = totals[metric] / counts[metric];
        } else {
          totals[metric] = 0;
        }
      });

      setTotalMetrics(totals);
      setMetricsFetched(true);
      setLastUpdateTime(new Date());
      
      toast.update(loadingToastId, { render: "Métricas carregadas com sucesso.", type: "success", isLoading: false, autoClose: 3000 });
    } catch (error) {
      // Erro será tratado pelo interceptor do Axios
      console.error('Erro ao buscar métricas:', error.response?.data || error);
      toast.update(loadingToastId, { render: "Erro ao carregar métricas.", type: "error", isLoading: false, autoClose: 5000 });
      setMetricsFetched(true);
    } finally {
      setLoading(false);
    }
  };

  const getCreativeType = (objectStorySpec) => {
    if (!objectStorySpec) return 'Não especificado';
    if (objectStorySpec.video_data) return 'Vídeo';
    if (objectStorySpec.template_data?.multi_share_end_card) return 'Carrossel';
    if (objectStorySpec.link_data) return 'Imagem';
    return 'Não especificado';
  };

  const getFilteredData = () => {
    let data = [];
    if (currentLevel === 'campaigns') {
      data = campaigns;
    } else if (currentLevel === 'adSets') {
      data = adSets;
    } else if (currentLevel === 'ads') {
      data = ads;
    }

    if (statusFilter !== 'Qualquer') {
      data = data.filter((item) =>
        statusFilter === 'Ativa'
          ? item.status === 'ACTIVE'
          : item.status !== 'ACTIVE'
      );
    }

    if (nameFilter) {
      data = data.filter((item) =>
        item.name.toLowerCase().includes(nameFilter)
      );
    }

    return data;
  };

  const handleItemClick = async (item) => {
    if (currentLevel === 'campaigns') {
      setSelectedItems({ campaigns: [item.id], adSets: [], ads: [] });
      await fetchAdSets([item.id]);
      setCurrentLevel('adSets');
    } else if (currentLevel === 'adSets') {
      setSelectedItems((prev) => ({
        ...prev,
        adSets: [item.id],
        ads: [],
      }));
      await fetchAds([item.id]);
      setCurrentLevel('ads');
    }
  };

  const handleCheckboxChange = (e, id) => {
    const isChecked = e.target.checked;
    if (currentLevel === 'campaigns') {
      setSelectedItems((prev) => ({
        ...prev,
        campaigns: isChecked
          ? [...prev.campaigns, id]
          : prev.campaigns.filter((itemId) => itemId !== id),
      }));
    } else if (currentLevel === 'adSets') {
      setSelectedItems((prev) => ({
        ...prev,
        adSets: isChecked
          ? [...prev.adSets, id]
          : prev.adSets.filter((itemId) => itemId !== id),
      }));
    } else if (currentLevel === 'ads') {
      setSelectedItems((prev) => ({
        ...prev,
        ads: isChecked
          ? [...prev.ads, id]
          : prev.ads.filter((itemId) => itemId !== id),
      }));
    }
  };

  const isItemSelected = (id) => {
    if (currentLevel === 'campaigns') {
      return selectedItems.campaigns.includes(id);
    } else if (currentLevel === 'adSets') {
      return selectedItems.adSets.includes(id);
    } else if (currentLevel === 'ads') {
      return selectedItems.ads.includes(id);
    }
    return false;
  };

  const areAllItemsSelected = () => {
    const data = getFilteredData();
    if (data.length === 0) return false;
    if (currentLevel === 'campaigns') {
      return data.every((item) => selectedItems.campaigns.includes(item.id));
    } else if (currentLevel === 'adSets') {
      return data.every((item) => selectedItems.adSets.includes(item.id));
    } else if (currentLevel === 'ads') {
      return data.every((item) => selectedItems.ads.includes(item.id));
    }
    return false;
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const data = getFilteredData();
    const ids = data.map((item) => item.id);

    if (currentLevel === 'campaigns') {
      setSelectedItems((prev) => ({
        ...prev,
        campaigns: isChecked ? ids : [],
      }));
    } else if (currentLevel === 'adSets') {
      setSelectedItems((prev) => ({
        ...prev,
        adSets: isChecked ? ids : [],
      }));
    } else if (currentLevel === 'ads') {
      setSelectedItems((prev) => ({
        ...prev,
        ads: isChecked ? ids : [],
      }));
    }
  };

  const handleDateChange = (e) => {
    setDatePreset('');
    const newDateRange = {
      ...dateRange,
      [e.target.name]: e.target.value,
    };
    setDateRange(newDateRange);

    // Salva a data no localStorage
    localStorage.setItem(`dateRange_${userEmail}`, JSON.stringify(newDateRange));

    // Atualiza as métricas automaticamente
    handleFetchMetrics();
  };

  const handleDatePresetClick = (preset) => {
    setDatePreset(preset);
    localStorage.setItem(`datePreset_${userEmail}`, preset);
    let since = '';
    let until = '';

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const last7Days = new Date(today);
    last7Days.setDate(last7Days.getDate() - 6); // Inclui hoje
    const last30Days = new Date(today);
    last30Days.setDate(last30Days.getDate() - 29); // Inclui hoje

    switch (preset) {
      case 'today':
        since = today.toISOString().split('T')[0];
        until = today.toISOString().split('T')[0];
        break;
      case 'yesterday':
        since = yesterday.toISOString().split('T')[0];
        until = yesterday.toISOString().split('T')[0];
        break;
      case 'last_7d':
        since = last7Days.toISOString().split('T')[0];
        until = today.toISOString().split('T')[0];
        break;
      case 'last_30d':
        since = last30Days.toISOString().split('T')[0];
        until = today.toISOString().split('T')[0];
        break;
      default:
        break;
    }

    setDateRange({ since, until });

    // Salva a data no localStorage
    localStorage.setItem(
      `dateRange_${userEmail}`,
      JSON.stringify({ since, until })
    );

    // Atualiza as métricas automaticamente
    handleFetchMetrics();
  };

  const handleFetchMetrics = () => {
    // Fetch metrics after date change
    if (currentLevel === 'campaigns') {
      fetchMetrics(campaigns, 'campaigns');
    } else if (currentLevel === 'adSets') {
      fetchMetrics(adSets, 'adSets');
    } else if (currentLevel === 'ads') {
      fetchMetrics(ads, 'ads');
    }
  };

  const formatMetricValue = (key, value) => {
    if (['spend', 'cpm', 'link_click_cpc', 'costPerResult'].includes(key)) {
      return value ? `R$ ${parseFloat(value).toFixed(2)}` : 'R$ 0.00';
    } else if (
      ['link_click_ctr', 'hookRate', 'holdRate', 'roi'].includes(key)
    ) {
      return value ? `${parseFloat(value).toFixed(2)}%` : '0.00%';
    } else if (key === 'frequency') {
      return value ? parseFloat(value).toFixed(2) : '0.00';
    } else if (
      [
        'totalResults',
        'inline_link_clicks',
        'impressions',
        'reach',
        'video3SecViews',
        'video75Views',
      ].includes(key)
    ) {
      return value ? parseInt(value, 10).toLocaleString() : '0';
    } else {
      return value || 'Não especificado';
    }
  };

  // Função para determinar se a conversão é via WhatsApp
  const isWhatsAppConversion = (item) => {
    if (currentLevel === 'campaigns') {
      // Verificar se algum adSet da campanha é do tipo WhatsApp
      const relatedAdSets = adSets.filter(adSet => adSet.campaign_id === item.id);
      return relatedAdSets.some(adSet => adSet.destination_type === 'WHATSAPP');
    } else if (currentLevel === 'adSets') {
      return item.destination_type === 'WHATSAPP'; // Verifique o valor exato retornado pela API
    } else if (currentLevel === 'ads') {
      return item.creative_type === 'WhatsApp'; // Verifique o valor exato retornado pela API
    }
    return false;
  };

  // ** Alteração **: Modificação na função getAdditionalPhrase para não aplicar mapeamento no nível de campanha
  const getAdditionalPhrase = (item, metric) => {
    if (currentLevel === 'campaigns') {
      return null; // Não aplicar mapeamento no nível de campanha
    }

    if (metric === 'costPerResult') {
      if (isWhatsAppConversion(item)) {
        return 'Custo por mensagem iniciada';
      } else {
        return 'Custo por compra';
      }
    } else if (metric === 'totalResults') {
      if (isWhatsAppConversion(item)) {
        return 'Conversa iniciada';
      } else {
        return 'Compra no site';
      }
    }
    return null;
  };

  // Função para lidar com logout e limpar apenas caches de métricas
  const handleLogout = () => {
    // Função para limpar apenas caches de métricas
    const clearMetricsCache = () => {
      const metricsKeys = [
        'adAccounts_',
        'campaigns_',
        'adSets_',
        'ads_',
        'selectedMetricsColors',
      ];
      const keysToRemove = [];
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && metricsKeys.some(prefix => key.startsWith(prefix) && key.includes(userEmail))) {
          keysToRemove.push(key);
        }
      }
      keysToRemove.forEach(key => localStorage.removeItem(key));

      // Também limpar do sessionStorage, se necessário
      const sessionKeysToRemove = [];
      for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);
        if (key && metricsKeys.some(prefix => key.startsWith(prefix) && key.includes(userEmail))) {
          sessionKeysToRemove.push(key);
        }
      }
      sessionKeysToRemove.forEach(key => sessionStorage.removeItem(key));
    };

    logout();
    localStorage.removeItem(`facebookToken_${userEmail}`); // Removendo token do localStorage
    sessionStorage.removeItem(`facebookToken_${userEmail}`); // Removendo token do sessionStorage
    clearMetricsCache(); // Limpar cache de métricas
    navigate('/login');
  };

  // Função para voltar ao menu
  const handleBackToMenu = () => {
    navigate('/ferramentas');
  };

  // Função chamada ao fazer login no Facebook com sucesso
  const handleFacebookLoginSuccess = (token) => {
    // ** Alteração **: Agora, o botão de login está sempre visível, então pode-se optar por alternar funções
    // Aqui, simplesmente atualizamos o token e notificamos
    localStorage.setItem(`facebookToken_${userEmail}`, token);
    setFacebookToken(token);
    toast.success('Login realizado com sucesso!', { // Notificação de sucesso
      position: "top-right",
      autoClose: 3000,
    });
  };

  // Função para obter o nome do usuário
  const userName = getUserName() || 'Usuário';

  // Função para obter a foto do usuário do localStorage
  const userPhoto =
    localStorage.getItem(`${userEmail}-fotoPerfil`) ||
    'https://via.placeholder.com/40';

  // Função para redirecionar para o Gerenciador do Facebook
  const openFacebookManager = () => {
    if (selectedAccount) {
      window.open(
        `https://business.facebook.com/adsmanager/manage/accounts?act=${selectedAccount}`,
        '_blank'
      );
    } else {
      window.open('https://business.facebook.com/adsmanager', '_blank');
    }
  };

  // Função para calcular o tempo desde a última atualização
  const getTimeSinceLastUpdate = () => {
    if (!lastUpdateTime) return '';
    const now = new Date();
    const diffInMinutes = Math.floor((now - lastUpdateTime) / (1000 * 60));
    if (diffInMinutes < 1) return 'Atualizado há menos de 1 minuto';
    return `Atualizado há ${diffInMinutes} minutos`;
  };

  const refreshData = () => {
    if (currentLevel === 'campaigns') {
      fetchCampaigns(selectedAccount);
    } else if (currentLevel === 'adSets') {
      const campaignsToFetch =
        selectedItems.campaigns.length > 0
          ? selectedItems.campaigns
          : campaigns.map((campaign) => campaign.id);
      fetchAdSets(campaignsToFetch);
    } else if (currentLevel === 'ads') {
      const adSetsToFetch =
        selectedItems.adSets.length > 0
          ? selectedItems.adSets
          : adSets.map((adSet) => adSet.id);
      fetchAds(adSetsToFetch);
    }
  };

  // Funções para obter informações adicionais
  const selectedCampaignInfo = () => {
    if (selectedItems.campaigns.length === 1) {
      const campaign = campaigns.find(
        (c) => c.id === selectedItems.campaigns[0]
      );
      if (campaign) {
        return {
          type: translateCampaignType(campaign.buying_type),
          objective: campaignObjectives[campaign.objective] || campaign.objective,
        };
      }
    }
    return {
      type: 'Múltiplos',
      objective: 'Múltiplos',
    };
  };

  const selectedAdSetInfo = () => {
    if (selectedItems.adSets.length === 1) {
      const adSet = adSets.find((a) => a.id === selectedItems.adSets[0]);
      if (adSet) {
        return {
          conversionType:
            destinationTypes[adSet.destination_type] || adSet.destination_type,
        };
      }
    }
    return {
      conversionType: 'Múltiplos',
    };
  };

  // Função para traduzir o tipo de campanha
  const translateCampaignType = (type) => {
    switch(type) {
      case 'AUCTION':
        return 'Leilão';
      case 'FIXED':
        return 'Fixo';
      // Adicione outras traduções conforme necessário
      default:
        return type;
    }
  };

  // Função para traduzir o objetivo da campanha
  const translateCampaignObjective = (objective) => {
    switch(objective) {
      case 'OUTCOME_ENGAGEMENT':
        return 'Engajamento';
      case 'OUTCOME_SALES':
        return 'Vendas';
      // Adicione outras traduções conforme necessário
      default:
        return objective;
    }
  };

  // Função para lidar com rearranjo de métricas
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(selectedMetrics);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSelectedMetrics(items);
    localStorage.setItem(`selectedMetrics_${userEmail}`, JSON.stringify(items));
  };

  // Funções para redimensionar colunas
  const handleMouseDown = (e, metric) => {
    e.preventDefault();
    setIsResizing(true);
    setCurrentMetric(metric);
  };

  const handleMouseMove = (e) => {
    if (!isResizing || !currentMetric) return;
    e.preventDefault();
    const thElement = document.querySelector(`th[data-metric="${currentMetric}"]`);
    if (!thElement) return;
    const width = e.clientX - thElement.getBoundingClientRect().left;
    setColumnWidths((prev) => ({
      ...prev,
      [currentMetric]: width > 50 ? width : 50,
    }));
  };

  const handleMouseUp = () => {
    setIsResizing(false);
    setCurrentMetric(null);
  };

  useEffect(() => {
    if (isResizing) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing]);

  return (
    <div className="facebook-ads-dashboard-container">
      <ToastContainer /> {/* Adicionando o container de notificações */}

      {/* Cabeçalho */}
      <header className="facebook-ads-dashboard-header">
        <div className="header-left">
          <img src={logo} alt="Logo" className="header-logo" />
          <button className="back-button" onClick={handleBackToMenu}>
            <FaArrowLeft /> Voltar para Menu
          </button>
        </div>
        <div className="header-right">
          <button className="fad-button refresh-btn" onClick={refreshData}>
            <FaSyncAlt />
          </button>
          <div className="facebook-login-container-small">
            {/* ** Alteração **: O botão de login agora está sempre visível */}
            <FacebookLogin onLoginSuccess={handleFacebookLoginSuccess} />
          </div>
          <div className="user-info">
            <img
              src={userPhoto}
              alt="Avatar do Usuário"
              className="user-avatar"
            />
            <span className="user-name">{userName}</span>
          </div>
          <button className="logout-button" onClick={handleLogout}>
            Sair
          </button>
        </div>
      </header>

      {/* Conteúdo Principal */}
      <div className="facebook-ads-dashboard-content">
        {/* Dashboard Principal */}
        <main className="fad-main-content">
          {/* Header do Dashboard com Botões de Filtragem */}
          <div className="fad-main-header">
            <div className="fad-main-header-left">
              <h1 className="fad-title">Gerenciamento de Anúncios</h1>
            </div>
            <div className="fad-main-header-right">
              <button
                className="fad-button open-manager-btn"
                onClick={openFacebookManager}
              >
                <FaBullhorn /> Abrir no Gerenciador
              </button>
              <button className="fad-button duplicate-btn">
                <FaCopy /> Duplicar Seleção
              </button>
              <button
                className="fad-button metrics-selector-btn"
                onClick={() => setShowMetricsSelector(true)}
              >
                Selecionar Métricas
              </button>
              <div className="fad-refresh-container">
                <span className="fad-update-text">
                  {getTimeSinceLastUpdate()}
                </span>
              </div>
            </div>
          </div>

          {/* Botões de Navegação */}
          <div className="fad-navigation-buttons">
            <button
              className={`fad-button nav-btn ${
                currentLevel === 'campaigns' ? 'active-nav' : ''
              }`}
              onClick={async () => {
                setCurrentLevel('campaigns');
                setSelectedItems({ campaigns: [], adSets: [], ads: [] });
                await fetchCampaigns(selectedAccount);
              }}
              disabled={loading}
            >
              Campanha
            </button>
            <button
              className={`fad-button nav-btn ${
                currentLevel === 'adSets' ? 'active-nav' : ''
              }`}
              onClick={async () => {
                if (selectedItems.campaigns.length > 0) {
                  setCurrentLevel('adSets');
                  await fetchAdSets(selectedItems.campaigns);
                } else {
                  toast.warning('Por favor, selecione uma campanha primeiro.', { // Notificação de aviso
                    position: "top-right",
                    autoClose: 3000,
                  });
                }
              }}
              disabled={loading || selectedItems.campaigns.length === 0}
            >
              Conjunto de Anúncio
            </button>
            <button
              className={`fad-button nav-btn ${
                currentLevel === 'ads' ? 'active-nav' : ''
              }`}
              onClick={async () => {
                if (selectedItems.adSets.length > 0) {
                  setCurrentLevel('ads');
                  await fetchAds(selectedItems.adSets);
                } else {
                  toast.warning('Por favor, selecione um conjunto de anúncios primeiro.', { // Notificação de aviso
                    position: "top-right",
                    autoClose: 3000,
                  });
                }
              }}
              disabled={loading || selectedItems.adSets.length === 0}
            >
              Anúncio
            </button>
          </div>

          {/* Filtros */}
          <div className="fad-filters">
            <div className="fad-filter-item">
              <label className="fad-filter-label">Nome</label>
              <input
                type="text"
                placeholder="Filtrar por nome"
                className="fad-filter-input"
                onChange={(e) => {
                  setNameFilter(e.target.value.toLowerCase());
                }}
              />
            </div>
            <div className="fad-filter-item">
              <label className="fad-filter-label">Status</label>
              <select
                className="fad-filter-select"
                value={statusFilter}
                onChange={(e) => {
                  setStatusFilter(e.target.value);
                }}
              >
                <option>Qualquer</option>
                <option>Ativa</option>
                <option>Inativa</option>
              </select>
            </div>
            <div className="fad-filter-item">
              <label className="fad-filter-label">Data</label>
              <div className="fad-date-selector-wrapper">
                <motion.div
                  className="fad-date-selector"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <button
                    onClick={() => setShowDateSelector(!showDateSelector)}
                    className="fad-button date-toggle-button"
                  >
                    <FaCalendarAlt />
                    {showDateSelector
                      ? ' Esconder Intervalo de Datas'
                      : ' Selecionar Intervalo de Datas'}
                  </button>
                  {showDateSelector && (
                    <motion.div
                      className="fad-date-selector-content"
                      initial={{ height: 0 }}
                      animate={{ height: 'auto' }}
                      transition={{ duration: 0.5 }}
                    >
                      <div className="fad-date-inputs">
                        <input
                          type="date"
                          name="since"
                          value={dateRange.since}
                          onChange={(e) => {
                            handleDateChange(e);
                          }}
                          className="fad-filter-input"
                        />
                        <input
                          type="date"
                          name="until"
                          value={dateRange.until}
                          onChange={(e) => {
                            handleDateChange(e);
                          }}
                          className="fad-filter-input"
                        />
                      </div>
                      <div className="fad-date-preset-buttons">
                        <button
                          onClick={() => {
                            handleDatePresetClick('today');
                          }}
                          className="fad-button small-button"
                        >
                          Hoje
                        </button>
                        <button
                          onClick={() => {
                            handleDatePresetClick('yesterday');
                          }}
                          className="fad-button small-button"
                        >
                          Ontem
                        </button>
                        <button
                          onClick={() => {
                            handleDatePresetClick('last_7d');
                          }}
                          className="fad-button small-button"
                        >
                          Últimos 7 dias
                        </button>
                        <button
                          onClick={() => {
                            handleDatePresetClick('last_30d');
                          }}
                          className="fad-button small-button"
                        >
                          Últimos 30 dias
                        </button>
                      </div>
                      {/* Botão "Buscar" */}
                      <button
                        className="fad-button fetch-metrics-btn"
                        onClick={handleFetchMetrics}
                      >
                        Buscar
                      </button>
                    </motion.div>
                  )}
                </motion.div>
              </div>
            </div>
            <div className="fad-filter-item">
              <label className="fad-filter-label">Conta de Anúncio</label>
              <select
                className="fad-filter-select"
                value={selectedAccount || ''}
                onChange={(e) => fetchCampaigns(e.target.value)}
              >
                <option value="">Selecione uma conta</option>
                {accounts.map((account) => (
                  <option key={account.id} value={account.id}>
                    {account.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="fad-filter-item">
              <label className="fad-filter-label">Produto</label>
              <select
                className="fad-filter-select"
                onChange={(e) => {
                  // Implementar filtro por produto, se aplicável
                }}
              >
                <option>Qualquer</option>
                {/* Adicione mais opções conforme necessário */}
              </select>
            </div>
          </div>

          {/* Informações Adicionais */}
          {currentLevel === 'campaigns' && (
            <div className="fad-additional-info">
              <div className="fad-info-item">
                <span className="fad-info-label">Tipo de Campanha:</span>
                <span className="fad-info-value">
                  {selectedCampaignInfo().type}
                </span>
              </div>
              <div className="fad-info-item">
                <span className="fad-info-label">Objetivo da Campanha:</span>
                <span className="fad-info-value">
                  {translateCampaignObjective(selectedCampaignInfo().objective)}
                </span>
              </div>
            </div>
          )}
          {currentLevel === 'adSets' && (
            <div className="fad-additional-info">
              <div className="fad-info-item">
                <span className="fad-info-label">Tipo de Conversão:</span>
                <span className="fad-info-value">
                  {selectedAdSetInfo().conversionType}
                </span>
              </div>
            </div>
          )}

          {/* Tabela de Dados Dinâmica */}
          <div className="fad-table-container">
            <table className="fad-table">
              <thead>
                <tr>
                  <th className="sticky-column">
                    <input
                      type="checkbox"
                      className="fad-checkbox"
                      checked={areAllItemsSelected()}
                      onChange={handleSelectAll}
                    />
                  </th>
                  <th className="sticky-column">Status</th>
                  <th className="sticky-column">Nome</th>
                  {selectedMetrics.map((metric) => (
                    <th
                      key={metric}
                      data-metric={metric}
                      style={{
                        width: columnWidths[metric]
                          ? `${columnWidths[metric]}px`
                          : 'auto',
                      }}
                      className="metric-column"
                    >
                      {metricNames[metric]}
                      {/* Removido o MetricColorSelector daqui */}
                      <div
                        className="resize-handle"
                        onMouseDown={(e) => handleMouseDown(e, metric)}
                      />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {getFilteredData().map((item) => (
                  <tr
                    key={item.id}
                    className={`fad-table-row ${
                      isItemSelected(item.id) ? 'selected-row' : ''
                    }`}
                  >
                    <td className="sticky-column">
                      <input
                        type="checkbox"
                        className="fad-checkbox"
                        checked={isItemSelected(item.id)}
                        onChange={(e) => handleCheckboxChange(e, item.id)}
                      />
                    </td>
                    <td className="sticky-column">
                      <div
                        className={`fad-status ${
                          item.status === 'ACTIVE' ? 'active' : 'inactive'
                        }`}
                      />
                    </td>
                    <td className="sticky-column">
                      <div
                        className="fad-item-name"
                        onClick={() => handleItemClick(item)}
                      >
                        {item.name}
                      </div>
                    </td>
                    {selectedMetrics.map((metric) => (
                      <td
                        key={metric}
                        className={`fad-metric-cell ${
                          selectedMetricsColors[metric] ? 'colored-cell' : ''
                        }`}
                        style={{
                          backgroundColor: selectedMetricsColors[metric] || 'transparent',
                        }}
                      >
                        {metrics[item.id]
                          ? formatMetricValue(
                              metric,
                              metrics[item.id][metric]
                            )
                          : loading
                          ? 'Carregando...'
                          : '0'}
                        {/* Adicionar frase condicional para 'Custo por Resultado' e 'Resultados' */}
                        {(metric === 'costPerResult' || metric === 'totalResults') && (
                          <div className="metric-subtext">
                            {getAdditionalPhrase(item, metric)}
                          </div>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
              {/* Linha de Totais */}
              <tfoot>
                <tr>
                  <td colSpan="3" className="sticky-column">Totais</td>
                  {selectedMetrics.map((metric) => (
                    <td
                      key={metric}
                      className={`fad-metric-cell ${
                        selectedMetricsColors[metric] ? 'colored-cell' : ''
                      }`}
                      style={{
                        backgroundColor: selectedMetricsColors[metric] || 'transparent',
                      }}
                    >
                      {formatMetricValue(metric, totalMetrics[metric])}
                    </td>
                  ))}
                </tr>
              </tfoot>
            </table>
          </div>

          {/* Seleção de Métricas */}
          {showMetricsSelector && (
            <div className="fad-modal">
              <div className="fad-modal-content">
                <h3>Selecionar Métricas</h3>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="metrics-list">
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="fad-metrics-list"
                      >
                        {metricOrder.map((metric, index) => (
                          <Draggable
                            key={metric}
                            draggableId={metric}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                className="fad-metric-checkbox"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <input
                                  type="checkbox"
                                  id={metric}
                                  name={metric}
                                  checked={selectedMetrics.includes(metric)}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedMetrics([
                                        ...selectedMetrics,
                                        metric,
                                      ]);
                                      localStorage.setItem(`selectedMetrics_${userEmail}`, JSON.stringify([...selectedMetrics, metric]));
                                    } else {
                                      setSelectedMetrics(
                                        selectedMetrics.filter(
                                          (m) => m !== metric
                                        )
                                      );
                                      localStorage.setItem(`selectedMetrics_${userEmail}`, JSON.stringify(selectedMetrics.filter((m) => m !== metric)));
                                      // Remover a cor associada se a métrica for desativada
                                      if (selectedMetricsColors[metric]) {
                                        const newColors = { ...selectedMetricsColors };
                                        delete newColors[metric];
                                        setSelectedMetricsColors(newColors);
                                        localStorage.setItem('selectedMetricsColors', JSON.stringify(newColors));
                                      }
                                    }
                                  }}
                                />
                                <label htmlFor={metric}>
                                  {metricNames[metric] || metric}
                                </label>
                                {/* Adicionando o MetricColorSelector ao lado do nome da métrica */}
                                {selectedMetrics.includes(metric) && (
                                  <MetricColorSelector
                                    metric={metric}
                                    selectedMetricsColors={selectedMetricsColors}
                                    setSelectedMetricsColors={setSelectedMetricsColors}
                                  />
                                )}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <button
                  className="fad-button"
                  onClick={() => setShowMetricsSelector(false)}
                >
                  Fechar
                </button>
              </div>
            </div>
          )}
        </main>
      </div>

      {/* Indicador de Carregamento Removido */}
      {/* Agora, as mensagens de carregamento são exibidas via notificações */}

      {/* Mensagem de Erro Removida */}
      {/* Agora, as mensagens de erro são exibidas via notificações */}
    </div>
  );
};

export default FacebookAdsDashboard;
