import React, { useEffect } from 'react';
import './prova-social.css'; // Alterado para o novo arquivo CSS
import logo from '../../imagens/logo_menu_login.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { isAuthenticated, logout } from '../../auth/auth';
import ChatProvaSocial from './ChatProvaSocial'; // Importando o Chat de Prova Social

const ProvaSocial = () => {
  const userEmail = localStorage.getItem('userEmail');
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login'); // Redireciona o usuário não autenticado para a tela de login
    }
  }, [navigate]);

  return (
    <div className="prova-social-container"> {/* Classe atualizada */}
      <div className="prova-social-header"> {/* Classe atualizada */}
        <img src={logo} alt="Logo" className="prova-social-logo" /> {/* Classe atualizada */}
        <div className="prova-social-user-info"> {/* Classe atualizada */}
          <span>{userEmail}</span>
          <button className="prova-social-button" onClick={logout}>Sair</button> {/* Classe atualizada */}
        </div>
      </div>
      <div className="prova-social-content"> {/* Classe atualizada */}
        <h1 className="prova-social-title-gradient">Criação de Provas Sociais Poderosas</h1> {/* Classe atualizada */}
        <p className="prova-social-description"> {/* Classe atualizada */}
          👥 <strong>Crie Provas Sociais Irresistíveis para seus Produtos!</strong> <br /><br />
          Nesta página, você vai utilizar nossa Inteligência Artificial para criar <strong>provas sociais de alta conversão</strong> para seus produtos. As provas sociais aumentam a confiança dos clientes e ajudam a <strong>alavancar suas vendas</strong>.<br /><br />
          💬 <strong>Deixe que a nossa IA crie as melhores provas sociais</strong> e conquiste mais credibilidade para o seu negócio, mostrando a experiência positiva de outros clientes.
        </p>

        <Link to="/ferramentas" className="prova-social-button-menu" style={{ textDecoration: 'none' }}>
          <FontAwesomeIcon icon={faArrowLeft} /> Voltar para o Menu
        </Link>

        <br /> {/* Adiciona uma linha de espaçamento */}
        <br /> {/* Adiciona mais uma linha de espaçamento */}

        {/* Chat de Prova Social renderizado abaixo do botão de voltar para o menu */}
        <ChatProvaSocial /> {/* Chat atualizado */}
      </div>
    </div>
  );
};

export default ProvaSocial;
