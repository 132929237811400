import React, { useEffect } from 'react';
import './ScriptVendasWhatsapp.css';
import logo from '../../imagens/logo_menu_login.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { isAuthenticated, logout } from '../../auth/auth';
import ChatWhatsapp from './ChatWhatsapp'; // Importando o Chat abaixo da descrição

const ScriptVendasWhatsapp = () => {
  const userEmail = localStorage.getItem('userEmail');
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login'); // Redireciona o usuário não autenticado para a tela de login
    }
  }, [navigate]);

  return (
    <div className="script-vendas-whatsapp-container">
      <div className="script-vendas-whatsapp-header">
        <img src={logo} alt="Logo" className="script-vendas-whatsapp-logo" />
        <div className="script-vendas-whatsapp-user-info">
          <span>{userEmail}</span>
          <button className="script-vendas-whatsapp-button" onClick={logout}>Sair</button>
        </div>
      </div>
      <div className="script-vendas-whatsapp-content">
    <h1 className="script-vendas-whatsapp-title-gradient">Script de Vendas para WhatsApp</h1>
    <p className="script-vendas-whatsapp-description">
        💬 <strong>Crie Seus Scripts de Venda Poderosos para WhatsApp e Fature na Internet!</strong> <br /><br />
        Nesta página, você vai utilizar nossa Inteligência Artificial para gerar <strong>scripts de venda de alta conversão</strong> prontos para o WhatsApp. Com poucos cliques, você terá em mãos textos persuasivos, desenhados para engajar seus clientes e <strong>aumentar suas vendas</strong>. Tudo feito para que qualquer pessoa, mesmo sem experiência, consiga criar <strong>mensagens impactantes</strong> e começar a faturar online.<br /><br />
        🚀 <strong>É simples, rápido e eficiente!</strong> Deixe nossa IA criar o texto perfeito enquanto você foca no que realmente importa: fechar mais vendas e prosperar na internet.
    </p>

<Link to="/ferramentas" className="script-vendas-whatsapp-button-menu" style={{ textDecoration: 'none' }}>
  <FontAwesomeIcon icon={faArrowLeft} /> Voltar para o Menu
</Link>

<br /> {/* Aqui você adiciona uma linha de espaçamento */}
<br /> {/* Aqui você adiciona mais uma linha de espaçamento */}

{/* Chat renderizado abaixo do botão de voltar para o menu */}
<ChatWhatsapp />
</div>
</div>
);
};


export default ScriptVendasWhatsapp;
