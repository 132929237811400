// src/components/FacebookAdsDashboard/MetricsConfig.js
export const metricNames = {
  impressions: 'Impressões',
  reach: 'Alcance',
  frequency: 'Frequência',
  spend: 'Gasto',
  cpm: 'CPM',
  totalResults: 'Resultados Totais',
  costPerResult: 'Custo por Resultado',
  inline_link_clicks: 'Cliques no Link',
  link_click_ctr: 'CTR de Cliques no Link',
  link_click_cpc: 'CPC de Cliques no Link',
  video3SecViews: 'Visualizações de 3 Segundos',
  video75Views: 'Visualizações de 75%',
  hookRate: 'hookRate',
  holdRate: 'holdRate',
  roi: 'ROI',
  // Adicione outras métricas conforme necessário
};

export const defaultMetrics = [
  'impressions',
  'reach',
  'frequency',
  'spend',
  'cpm',
  'totalResults',
  'costPerResult',
  'inline_link_clicks',
  'link_click_ctr',
  'link_click_cpc',
  'video3SecViews',
  'video75Views',
  'hookRate',
  'holdRate',
  'roi',
  // Adicione outras métricas conforme necessário
];

export const sumMetrics = ['impressions', 'reach', 'spend', 'inline_link_clicks', 'costPerResult'];
export const averageMetrics = ['link_click_ctr', 'hookRate', 'holdRate', 'roi'];

export const metricOrder = [
  'impressions',
  'reach',
  'frequency',
  'spend',
  'cpm',
  'totalResults',
  'costPerResult',
  'inline_link_clicks',
  'link_click_ctr',
  'link_click_cpc',
  'video3SecViews',
  'video75Views',
  'hookRate',
  'holdRate',
  'roi',
  // Adicione outras métricas conforme necessário
];
