import React, { useEffect, useState } from 'react';
import { logout, isAuthenticated, getUserEmail, getUserName } from '../../auth/auth';
import { useNavigate, Link } from 'react-router-dom';
import './Menu.css';
import logo from '../../imagens/logo_menu_login.webp';
import MenuVertical from './MenuVertical';
import { supabase } from '../../supabaseClient';

// Importando os arquivos necessários para a notificação
import welcomeSound from '../../assets/sounds/welcome.MP3';
import mensagens from '../../assets/mensagens.json';

// Importando as imagens para o menu
import minhaContaImg from '../../imagens/minha-conta.webp';
import suporteClienteImg from '../../imagens/suporte-cliente.webp';
import ferramentasImg from '../../imagens/ferramentas.webp';
import treinamentoImg from '../../imagens/pensa.webp';

// Definição das categorias e suas condições de acesso
const categories = [
  {
    title: 'Ferramentas para Prosperar no Digital',
    items: [
      { img: ferramentasImg, alt: 'Ferramentas', link: '/ferramentas' },
    ],
    access: (produtos, planoId) => produtos.some(p => p.id === 4) || planoId === 1,
  },
  {
    title: 'Projeto Meu Primeiro Salário Sem Sair de Casa',
    items: [
      { img: treinamentoImg, alt: 'Treinamento', link: '/treinamentos' },
    ],
    access: (produtos) => produtos.some(p => p.id === 1),
  },
  {
    title: 'Administração',
    items: [
      { img: minhaContaImg, alt: 'Minha Conta', link: '/minha-conta' },
      { img: suporteClienteImg, alt: 'Suporte ao Cliente', link: '/suporte' },
    ],
    access: () => true,
  },
];

const Menu = () => {
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = useState(false);
  const [userName, setUserName] = useState('');
  const [randomMessage, setRandomMessage] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [produtos, setProdutos] = useState([]);
  const [planoId, setPlanoId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isAuthenticated()) {
      const email = getUserEmail();
      const name = getUserName();
      setUserEmail(email);
      setUserName(name);

      // Verifica se os produtos estão no localStorage, caso contrário, faz uma consulta ao banco
      const storedProdutos = localStorage.getItem('produtosComprados');
      const storedPlanoId = localStorage.getItem('planoId');

      if (storedProdutos) {
        setProdutos(JSON.parse(storedProdutos));
      } else {
        fetchProdutosDoBanco();
      }

      if (storedPlanoId) {
        setPlanoId(parseInt(storedPlanoId));
      } else {
        fetchPlanoIdDoBanco();
      }

      // Exibe mensagem de boas-vindas
      const lastLoginTime = localStorage.getItem('lastLoginTime');
      const currentTime = new Date().getTime();
      const tenMinutes = 10 * 60 * 1000;

      if (!lastLoginTime || currentTime - lastLoginTime > tenMinutes) {
        localStorage.setItem('lastLoginTime', currentTime);
        setShowMessage(true);
        const randomIndex = Math.floor(Math.random() * mensagens.length);
        setRandomMessage(mensagens[randomIndex] || 'Você nasceu para prosperar!🚀');
      }

      setLoading(false);
    } else {
      navigate('/login');
    }
  }, [navigate]);
  
  const playWelcomeSound = () => {
    const audio = new Audio(welcomeSound);
    audio.play().catch(error => console.error('Erro ao tocar o som:', error));
  };

  useEffect(() => {
    if (showMessage) {
      playWelcomeSound();
    }
  }, [showMessage]);

  // Função para buscar produtos do banco de dados se não estiverem no localStorage
  const fetchProdutosDoBanco = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const { data: compras, error } = await supabase
        .from('compras')
        .select('produto_id')
        .eq('usuario_id', userId);

      if (error) {
        console.error('Erro ao buscar produtos no banco de dados:', error);
      } else {
        const produtosDisponiveis = compras.map(c => ({ id: c.produto_id }));
        localStorage.setItem('produtosComprados', JSON.stringify(produtosDisponiveis));
        setProdutos(produtosDisponiveis);
      }
    } catch (error) {
      console.error('Erro ao consultar produtos do banco:', error);
    }
  };

  // Função para buscar o plano_id do usuário na tabela usuarios
  const fetchPlanoIdDoBanco = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const { data: usuario, error } = await supabase
        .from('usuarios')
        .select('plano_id')
        .eq('user_id', userId)
        .single();

      if (error) {
        console.error('Erro ao buscar plano do usuário no banco de dados:', error);
      } else {
        localStorage.setItem('planoId', usuario.plano_id);
        setPlanoId(usuario.plano_id);
      }
    } catch (error) {
      console.error('Erro ao buscar plano no banco:', error);
    }
  };

  const handleLogout = () => {
    logout();
  };

  const handleCloseMessage = () => {
    setShowMessage(false);
  };

  // Função para verificar acesso a uma categoria
  const hasAccess = (category) => {
    return category.access(produtos, planoId);
  };

  return (
    <div className="menu-container-unique">
      <MenuVertical />
      {loading ? (
        <p>Carregando...</p>
      ) : (
        <>
          {showMessage && (
            <div className="message-popup-unique">
              <h2>Seja muito bem-vindo(a), <span className="user-name-unique">{userName}</span>!</h2>
              <p>{randomMessage} <span className="highlight-unique">Esta mensagem é para você!</span></p>
              <button className="close-btn-unique" onClick={handleCloseMessage}>Fechar</button>
              <p className="footer-message-unique">Você nasceu para prosperar!🚀</p>
            </div>
          )}
          <div className="header-unique">
            <img src={logo} alt="Logo" className="menu-logo-unique" />
            <div className="user-info-unique">
              <span>{userEmail}</span>
              <button onClick={handleLogout}>Sair</button>
            </div>
          </div>
          <div className="content-unique">
            <h1 className="title-gradient-unique">O Caminho para a Prosperidade Digital</h1>
            <p className="description-unique">"Bem-vindo! Aqui você encontra as ferramentas essenciais para iniciar sua jornada digital e alcançar a prosperidade."</p>

            {categories.map((category, index) => (
              <div key={index}>
                <div className="category-title-unique">{category.title}</div>
                <div className="menu-items-unique">
                  {category.items.map((item, idx) => {
                    const access = hasAccess(category);
                    return (
                      <div key={idx} className={`menu-item-unique ${!access ? 'blocked' : ''}`}>
                        <img src={item.img} alt={item.alt} className="menu-img" />
                        <Link to={item.link} className="no-underline-unique">
                          <button
                            className="btn-unique"
                            disabled={!access}
                            onClick={(e) => {
                              if (!access) {
                                e.preventDefault();
                                alert('Acesso restrito. Seu plano atual não permite acessar esta categoria.');
                              }
                            }}
                          >
                            {access ? 'Acesse aqui' : 'Bloqueado'}
                          </button>
                        </Link>
                        {!access && (
                          <>
                            <div className="overlay-blocked">Bloqueado</div>
                            <a href="/comprar-produto" className="buy-link">Comprar Produto</a>
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Menu;
