// src/components/FacebookAdsDashboard/cache.js
export const getCachedData = (key) => {
  const cached = localStorage.getItem(key);
  if (!cached) return null;
  const parsed = JSON.parse(cached);
  const now = new Date().getTime();
  if (now - parsed.timestamp > 20 * 60 * 1000) { // 20 minutos
    localStorage.removeItem(key);
    return null;
  }
  return parsed.data;
};

export const setCachedData = (key, data) => {
  const cached = {
    data,
    timestamp: new Date().getTime(),
  };
  localStorage.setItem(key, JSON.stringify(cached));
};
