import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './PlanejadorProsperidade.css';
import logo from '../../imagens/logo_menu_login.webp';
import { isAuthenticated, logout } from '../../auth/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faChartLine, faCalendarAlt, faMoneyBillWave, faAd, faBullhorn, faChartPie } from '@fortawesome/free-solid-svg-icons';
import * as htmlToImage from 'html-to-image';
import jsPDF from 'jspdf';

const PlanejadorProsperidade = () => {
  const [budget, setBudget] = useState('');
  const [plan, setPlan] = useState(null);
  const navigate = useNavigate(); // Hook para navegação

  const userEmail = localStorage.getItem('userEmail');
  const userName = localStorage.getItem('userName');

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login'); // Redirecionamento utilizando useNavigate
    }

    // Efeito de movimento na entrada
    const inputContainer = document.querySelector('.planejador-slide-in-container');
    inputContainer.classList.add('planejador-slide-in');
  }, [navigate]);

  const determineCampaignStructure = (dailyBudget, testType) => {
    if (testType === 'criativos') {
      if (dailyBudget <= 20) {
        return dailyBudget <= 10 ? 'Estrutura 1-1-3 (CBO)' : 'Estrutura 1-2-2 (CBO)';
      } else if (dailyBudget <= 40) {
        return 'Estrutura 1-1-4 (CBO)';
      } else if (dailyBudget <= 50) {
        return 'Estrutura 1-3-1 (ABO)';
      } else {
        return 'Estrutura 1-3-3 (CBO)';
      }
    } else if (testType === 'públicos') {
      if (dailyBudget <= 20) {
        return dailyBudget <= 10 ? 'Estrutura 1-2-1 (CBO)' : 'Estrutura 1-3-1 (CBO)';
      } else if (dailyBudget <= 40) {
        return 'Estrutura 1-2-2 (ABO)';
      } else if (dailyBudget <= 50) {
        return 'Estrutura 1-2-3 (CBO)';
      } else {
        return 'Estrutura 1-4-4 (CBO)';
      }
    } else if (testType === 'escala') {
      if (dailyBudget <= 100) {
        return 'Estrutura 2-2-2 (CBO)';
      } else {
        return 'Estrutura 1-6-2 (CBO)';
      }
    }
  };

  const calculateDailyBudget = (totalBudget, days) => totalBudget / days;

  const getCampaignPlan = (totalBudget) => {
    let days;
    if (totalBudget <= 300) {
      days = 7;
    } else if (totalBudget <= 750) {
      days = 15;
    } else {
      days = 30;
    }

    const dailyBudget = calculateDailyBudget(totalBudget, days);

    const creativeStructure = determineCampaignStructure(dailyBudget, 'criativos');
    const publicStructure = determineCampaignStructure(dailyBudget, 'públicos');
    const scaleStructure = determineCampaignStructure(dailyBudget, 'escala');

    return {
      days,
      dailyBudget,
      creativeStructure,
      publicStructure,
      scaleStructure,
    };
  };

  const handleGeneratePlan = () => {
    const totalBudget = parseFloat(budget.replace('.', '').replace(',', '.'));
    if (isNaN(totalBudget) || totalBudget <= 0) {
      alert('Por favor, insira um valor válido.');
      return;
    }

    const campaignPlan = getCampaignPlan(totalBudget);
    setPlan(campaignPlan);
    const resultsElement = document.getElementById('plan-details');
    resultsElement.style.display = 'block';
    window.scrollTo({ top: resultsElement.offsetTop, behavior: 'smooth' });
  };

  const handleBudgetChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    const formattedValue = value.replace(/(\d)(\d{2})$/, '$1,$2').replace(/(?=(\d{3})+(\D))\B/g, '.');
    setBudget(formattedValue);
  };

  const renderCampaignStructure = (structure, dailyBudget) => {
    const [campaigns, adSets, ads] = structure.match(/\d+/g).map(Number);
    const campaignType = structure.includes('CBO') ? 'Campanha CBO' : 'Campanha ABO';
    const budgetPerAdSet = (dailyBudget / adSets).toFixed(2);
    const adSetElements = Array.from({ length: adSets }).map((_, adSetIndex) => (
      <div key={adSetIndex} className="adset">
        Conjunto de Anúncios - R$ {campaignType === 'Campanha ABO' ? budgetPerAdSet : ''}
        {Array.from({ length: ads }).map((_, adIndex) => (
          <div key={adIndex} className="ad">Anúncio</div>
        ))}
      </div>
    ));

    return (
      <div className="campaign-structure">
        <div className="campaign-block">{campaignType} - R$ {campaignType === 'Campanha CBO' ? dailyBudget.toFixed(2) : ''}</div>
        {adSetElements}
      </div>
    );
  };

  const downloadAsImage = () => {
    const element = document.getElementById('plan-details');
    const logoElement = document.createElement('img');
    logoElement.src = logo;
    logoElement.className = 'print-logo';
    element.insertBefore(logoElement, element.firstChild);
    htmlToImage.toPng(element)
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'plano.png';
        link.click();
        element.removeChild(logoElement);
      })
      .catch((error) => {
        console.error('Erro ao salvar como imagem:', error);
        element.removeChild(logoElement);
      });
  };

  const renderExplanations = () => (
    <div className="explanation">
      <h3 className="explanation-title">Explicações sobre a Estrutura de Campanha</h3>
      <p className="explanation-text">
        <FontAwesomeIcon icon={faBullhorn} className="explanation-icon" />
        <strong>Campanha:</strong> Onde você define o objetivo do anúncio, como conversão ou alcance.
      </p>
      <p className="explanation-text">
        <FontAwesomeIcon icon={faChartPie} className="explanation-icon" />
        <strong>Conjunto de Anúncios:</strong> Onde você define seu público-alvo, orçamento e cronograma.
      </p>
      <p className="explanation-text">
        <FontAwesomeIcon icon={faAd} className="explanation-icon" />
        <strong>Anúncios:</strong> Onde você cria os anúncios em si, definindo imagens, vídeos e textos.
      </p>
      <h3 className="explanation-title">Explicações sobre o que é CBO e ABO</h3>
      <p className="explanation-text">
        <FontAwesomeIcon icon={faBullhorn} className="explanation-icon" />
        <strong>CBO (Campaign Budget Optimization):</strong> O Facebook distribui automaticamente o orçamento entre os conjuntos de anúncios para maximizar os resultados. Exemplo: se você tem um orçamento de R$ 50 e 3 conjuntos de anúncios, o Facebook pode distribuir o valor entre eles de acordo com o desempenho.
      </p>
      <p className="explanation-text">
        <FontAwesomeIcon icon={faBullhorn} className="explanation-icon" />
        <strong>ABO (Ad Set Budget Optimization):</strong> O orçamento é definido manualmente para cada conjunto de anúncios. Exemplo: se você tem um orçamento de R$ 50 e 3 conjuntos de anúncios, você define quanto cada conjunto receberá, como R$ 20 para um e R$ 15 para os outros dois.
      </p>
      <p className="explanation-text">
        <FontAwesomeIcon icon={faBullhorn} className="explanation-icon" />
        <strong>Para ativar o CBO na sua campanha do Facebook:</strong>
        <ol>
          <li>Vá para o Gerenciador de Anúncios do Facebook.</li>
          <li>Crie uma nova campanha ou edite uma existente.</li>
          <li>Na configuração da campanha, encontre a opção "Otimização do Orçamento da Campanha" e ative-a.</li>
          <li>Defina o orçamento total da campanha.</li>
          <li>Continue configurando os conjuntos de anúncios e anúncios normalmente.</li>
        </ol>
      </p>

      <h3 className="explanation-title">Escolhendo o Objetivo da Campanha</h3>
      <p className="explanation-text">
        <FontAwesomeIcon icon={faBullhorn} className="explanation-icon" />
        <strong>Campanha de Engajamento com Objetivo de Mensagem para o WhatsApp:</strong> Focada em gerar interações e mensagens no WhatsApp, geralmente atrai um público mais amplo e menos qualificado, mas com um custo por mensagem mais baixo. Exemplo: se seu objetivo é receber muitas mensagens rapidamente, essa pode ser uma boa opção.
      </p>
      <p className="explanation-text">
        <FontAwesomeIcon icon={faBullhorn} className="explanation-icon" />
        <strong>Campanha de Conversão com Objetivo de Mensagem para o WhatsApp:</strong> Focada em gerar mensagens no WhatsApp com um público mais qualificado, geralmente com um custo por mensagem mais alto. Exemplo: se você quer receber mensagens de pessoas com maior intenção de compra, essa é a melhor opção.
      </p>

      <h3 className="explanation-title">Estrutura da Campanha</h3>
      <p className="explanation-text">
        <FontAwesomeIcon icon={faBullhorn} className="explanation-icon" />
        <strong>Teste de Criativos:</strong> Cada conjunto de anúncio deve ter criativos diferentes para testar qual performa melhor. Exemplo: se você tem 3 conjuntos de anúncios, cada um deve ter 3 criativos diferentes.
      </p>
      <p className="explanation-text">
        <FontAwesomeIcon icon={faBullhorn} className="explanation-icon" />
        <strong>Teste de Públicos:</strong> Utilize os criativos validados no teste de criativos e teste diferentes públicos para encontrar o melhor público-alvo. Exemplo: se você tem 3 conjuntos de anúncios, todos podem ter os mesmos criativos, mas com públicos diferentes.
      </p>

      <h3 className="explanation-title">Métricas a Serem Analisadas</h3>
      <ul>
        <li>
          <FontAwesomeIcon icon={faChartLine} className="explanation-icon" />
          <strong>CTR (Click-Through Rate):</strong> Taxa de cliques. Exemplo: Se 100 pessoas viram seu anúncio e 10 clicaram, seu CTR é 10%.
        </li>
        <li>
          <FontAwesomeIcon icon={faMoneyBillWave} className="explanation-icon" />
          <strong>CPC (Cost Per Click):</strong> Custo por clique. Exemplo: Se você gastou R$ 10 e teve 10 cliques, seu CPC é R$ 1.
        </li>
        <li>
          <FontAwesomeIcon icon={faCalendarAlt} className="explanation-icon" />
          <strong>Conversões:</strong> Ações desejadas resultantes dos cliques nos anúncios. Exemplo: Compras, cadastros, etc.
        </li>
        <li>
          <FontAwesomeIcon icon={faEnvelope} className="explanation-icon" />
          <strong>Custo por Mensagem:</strong> Custo para gerar uma mensagem via WhatsApp.
        </li>
        <li>
          <FontAwesomeIcon icon={faEnvelope} className="explanation-icon" />
          <strong>Taxa de Conversão no WhatsApp:</strong> Percentual de mensagens que resultam em uma conversão. Recomenda-se uma taxa mínima de 10%.
        </li>
      </ul>
    </div>
  );

  return (
    <div className="planejador-prosperidade-container">
      <div className="planejador-prosperidade-header">
        <img src={logo} alt="Logo" className="planejador-prosperidade-logo" />
        <div className="planejador-prosperidade-user-info">
          <FontAwesomeIcon icon={faEnvelope} />
          <span>{userEmail}</span>
          <button className="planejador-prosperidade-button" onClick={logout}>Sair</button>
        </div>
      </div>
      <div className="planejador-prosperidade-content">
        <h1 className="planejador-prosperidade-title-gradient">Guia de Investimento Fácil</h1>
        <p className="planejador-prosperidade-description">Insira o valor que você tem disponível para investir no seu projeto digital:</p>
        <Link to="/ferramentas" className="planejador-prosperidade-button">← Voltar para o Menu</Link>
        <div className="planejador-slide-in-container">
          <input
            type="text"
            value={budget}
            onChange={handleBudgetChange}
            placeholder="R$ 0,00"
            className="planejador-budget-input"
          />
          <button onClick={handleGeneratePlan} className="planejador-generate-button">Gerar Plano</button>
        </div>
        {plan && (
          <div id="plan-details" className="planejador-prosperidade-results">
            <h2>Seu Plano Personalizado, <span className="highlighted-name">{userName}</span>, Para Você Começar sua Jornada no <span className="highlighted-name">Facebook ADS</span></h2>

            <h3>Fase 1 - Teste de Criativos (1 a 3 dias)</h3>
            <p>
              <FontAwesomeIcon icon={faChartPie} className="explanation-icon" />
              <strong>Estrutura de Campanha:</strong> {plan.creativeStructure}
            </p>
            <p>
              <FontAwesomeIcon icon={faMoneyBillWave} className="explanation-icon" />
              <strong>Orçamento Diário em Anúncios:</strong> R$ {plan.dailyBudget.toFixed(2)}
            </p>
            <p>
              <FontAwesomeIcon icon={faCalendarAlt} className="explanation-icon" />
              <strong>Duração:</strong> {plan.days} dias (Tempo total que o orçamento irá durar)
            </p>
            <p>
              <FontAwesomeIcon icon={faChartLine} className="explanation-icon" />
              <strong>Período:</strong> 4 dias (Tempo que cada fase irá durar)
            </p>
            <p><strong>Observação:</strong> Verifique diariamente o desempenho dos anúncios.</p>
            {renderCampaignStructure(plan.creativeStructure, plan.dailyBudget)}

            <h3>Fase 2 - Teste de Públicos (até 7 dias)</h3>
            <p>
              <FontAwesomeIcon icon={faChartPie} className="explanation-icon" />
              <strong>Estrutura de Campanha:</strong> {plan.publicStructure}
            </p>
            <p>
              <FontAwesomeIcon icon={faMoneyBillWave} className="explanation-icon" />
              <strong>Orçamento Diário em Anúncios:</strong> R$ {plan.dailyBudget.toFixed(2)}
            </p>
            <p>
              <FontAwesomeIcon icon={faCalendarAlt} className="explanation-icon" />
              <strong>Duração:</strong> {plan.days - 4} dias (Tempo total que o orçamento irá durar)
            </p>
            <p>
              <FontAwesomeIcon icon={faChartLine} className="explanation-icon" />
              <strong>Período:</strong> 7 dias (Tempo que cada fase irá durar)
            </p>
            <p><strong>Observação:</strong> Verifique diariamente o desempenho dos anúncios.</p>
            {renderCampaignStructure(plan.publicStructure, plan.dailyBudget)}

            {plan.dailyBudget >= 50 && (
              <>
                <h3>Fase 3 - Escala (após validar criativos e públicos)</h3>
                <p>
                  <FontAwesomeIcon icon={faChartPie} className="explanation-icon" />
                  <strong>Estrutura de Campanha:</strong> {plan.scaleStructure}
                </p>
                <p>
                  <FontAwesomeIcon icon={faMoneyBillWave} className="explanation-icon" />
                  <strong>Orçamento Diário em Anúncios:</strong> R$ {plan.dailyBudget.toFixed(2)}
                </p>
                <p>
                  <FontAwesomeIcon icon={faCalendarAlt} className="explanation-icon" />
                  <strong>Duração:</strong> {plan.days} dias (Tempo total que o orçamento irá durar)
                </p>
                <p>
                  <FontAwesomeIcon icon={faChartLine} className="explanation-icon" />
                  <strong>Período:</strong> {plan.days - 7} dias (Tempo que cada fase irá durar)
                </p>
                <p><strong>Observação:</strong> Verifique diariamente o desempenho dos anúncios.</p>
                {renderCampaignStructure(plan.scaleStructure, plan.dailyBudget)}
              </>
            )}

            {renderExplanations()}

            <h2>Conclusão e Advertência</h2>
            <p>Este plano permite testar a viabilidade do seu negócio dentro do orçamento estabelecido. Conforme ganha experiência, faça ajustes precisos no seu planejamento financeiro.</p>
            <h3>Importante:</h3>
            <ul>
              <li>Invista apenas o dinheiro que não vai prejudicar sua vida financeira</li>
              <li>Todo investimento tem riscos</li>
              <li>Esteja preparado para a possibilidade de não obter resultados imediatos</li>
              <li>O dinheiro investido deve ser considerado como parte do aprendizado</li>
              <li>Nunca comprometa suas necessidades básicas ou emergências por esse investimento</li>
            </ul>

            <button onClick={downloadAsImage} className="planejador-save-button">Salvar como Imagem</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlanejadorProsperidade;
