import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './ConversaoMagica.css';
import logo from '../../imagens/logo_menu_login.webp';
import { isAuthenticated, logout } from '../../auth/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const ConversaoMagica = () => {
  const [mensagens, setMensagens] = useState('');
  const [vendas, setVendas] = useState('');
  const [taxaConversao, setTaxaConversao] = useState(null);
  const [recomendacao, setRecomendacao] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login'); // Redireciona o usuário não autenticado para a tela de login
    }
  }, [navigate]);

  const calcularTaxaConversao = () => {
    const mensagensNum = parseFloat(mensagens);
    const vendasNum = parseFloat(vendas);

    if (isNaN(mensagensNum) || isNaN(vendasNum) || mensagensNum === 0) {
      setTaxaConversao(null);
      setRecomendacao('Por favor, insira números válidos e certifique-se de que o número de mensagens não seja zero.');
      return;
    }

    const taxa = (vendasNum / mensagensNum) * 100;
    setTaxaConversao(taxa.toFixed(2));

    if (taxa >= 10) {
      setRecomendacao('Parabéns! Sua taxa de conversão é excelente.');
    } else {
      setRecomendacao(
        <div className="recomendacao-container">
          <p>A taxa de conversão está abaixo de 10%. Aqui estão algumas dicas para melhorar:</p>
          <ol>
            <li>
              <strong>1. Preparação:</strong>
              <ul>
                <li><strong>Conheça o Produto:</strong> Certifique-se de entender bem o produto que está vendendo. Conheça seus benefícios, características, e diferenciais. Isso ajudará a transmitir confiança e conhecimento ao cliente.</li>
                <li><strong>Defina o Público-Alvo:</strong> Tenha clareza sobre quem é o seu cliente ideal. Isso ajudará a personalizar a abordagem e destacar os benefícios que mais importam para aquele público.</li>
              </ul>
            </li>
            <li>
              <strong>2. Abordagem Inicial:</strong>
              <ul>
                <li><strong>Cumprimento Personalizado:</strong> Inicie a conversa com um cumprimento amigável, usando o nome do cliente, se possível. Exemplo: "Olá, [Nome]! Tudo bem com você?"</li>
                <li><strong>Objetivo Claro:</strong> Seja claro sobre o objetivo da conversa desde o início. Por exemplo, "Gostaria de compartilhar com você um produto que pode [benefício principal]."</li>
              </ul>
            </li>
            <li>
              <strong>3. Apresentação do Produto:</strong>
              <ul>
                <li><strong>Problema e Solução:</strong> Identifique um problema comum que o cliente possa ter e apresente o produto como uma solução. Exemplo: "Muitas pessoas têm dificuldade em encontrar [produto] de qualidade. Nosso [produto] resolve isso ao oferecer [característica/benefício]."</li>
                <li><strong>Benefícios Claros:</strong> Foque nos benefícios mais relevantes para o cliente. Em vez de listar características técnicas, explique como o produto pode melhorar a vida do cliente.</li>
              </ul>
            </li>
            <li>
              <strong>4. Prova Social e Testemunhos:</strong>
              <ul>
                <li><strong>Depoimentos e Avaliações:</strong> Se possível, compartilhe testemunhos de clientes satisfeitos. Isso ajuda a construir confiança e valida a eficácia do produto. Exemplo: "Aqui estão alguns depoimentos de clientes que adoraram nosso produto!"</li>
              </ul>
            </li>
            <li>
              <strong>5. Ofertas e Promoções:</strong>
              <ul>
                <li><strong>Promoção Limitada:</strong> Ofereça uma promoção ou desconto para incentivar a compra imediata. Exemplo: "Estamos oferecendo um desconto de 10% para os primeiros 10 compradores!"</li>
                <li><strong>Call to Action (CTA):</strong> Termine a apresentação com um chamado para ação claro, como "Gostaria de aproveitar essa oferta especial hoje?"</li>
              </ul>
            </li>
            <li>
              <strong>6. Resolução de Dúvidas e Objeções:</strong>
              <ul>
                <li><strong>Escuta Ativa:</strong> Esteja preparado para responder perguntas e resolver dúvidas de forma clara e paciente. Isso ajuda a construir confiança e remove barreiras à compra.</li>
                <li><strong>Objeções Comuns:</strong> Esteja preparado para lidar com objeções comuns, como preço, qualidade ou necessidade do produto. Tenha respostas preparadas que reforcem o valor do produto.</li>
              </ul>
            </li>
            <li>
              <strong>7. Fechamento da Venda:</strong>
              <ul>
                <li><strong>Simplifique o Processo:</strong> Facilite o processo de compra. Forneça informações claras sobre pagamento, entrega e suporte pós-venda.</li>
                <li><strong>Agradecimento e Confirmação:</strong> Agradeça o cliente pela compra e confirme os detalhes do pedido. Exemplo: "Muito obrigado por sua compra, [Nome]! Seu pedido será enviado para [endereço]."</li>
              </ul>
            </li>
            <li>
              <strong>8. Pós-venda:</strong>
              <ul>
                <li><strong>Follow-up:</strong> Após a venda, faça um follow-up para garantir que o cliente esteja satisfeito com o produto. Isso pode abrir portas para futuras vendas e indicações.</li>
              </ul>
            </li>
            <li>
              <strong>9. Melhoria Contínua:</strong>
              <ul>
                <li><strong>Feedback e Ajustes:</strong> Peça feedback aos clientes sobre sua experiência de compra e use essas informações para ajustar e melhorar continuamente o script de vendas.</li>
              </ul>
            </li>
          </ol>
          <p><strong>Conclusão:</strong> Seguindo este roteiro, você pode melhorar sua taxa de conversão ao criar uma experiência de compra positiva e personalizada para seus clientes no WhatsApp. Lembre-se de que a chave para o sucesso é entender as necessidades do cliente e apresentar soluções de forma clara e empática.</p>
        </div>
      );
    }
  };

  return (
    <div className="conversao-magica-container">
      <div className="conversao-magica-header">
        <img src={logo} alt="Logo" className="conversao-magica-logo" />
        <div className="conversao-magica-user-info">
          <span>{localStorage.getItem('userEmail')}</span>
          <button className="conversao-magica-button" onClick={logout}>Sair</button>
        </div>
      </div>
      <div className="conversao-magica-content">
        <h1 className="conversao-magica-title-gradient">Conversão Mágica no WhatsApp</h1>
        <p className="conversao-magica-description">
          Descubra sua taxa de conversão e aprenda a melhorar suas vendas no WhatsApp.
        </p>
        <Link to="/ferramentas" className="conversao-magica-button-large">← Voltar para o Menu</Link>
        <div className="conversao-magica-form">
          <div className="input-group">
            <label htmlFor="mensagens">Número de Mensagens Recebidas:</label>
            <input
              type="number"
              id="mensagens"
              value={mensagens}
              onChange={(e) => setMensagens(e.target.value)}
              placeholder="Ex: 100"
            />
          </div>
          <div className="input-group">
            <label htmlFor="vendas">Número de Vendas Realizadas:</label>
            <input
              type="number"
              id="vendas"
              value={vendas}
              onChange={(e) => setVendas(e.target.value)}
              placeholder="Ex: 10"
            />
          </div>
          <button onClick={calcularTaxaConversao} className="conversao-magica-button-large">Calcular Taxa de Conversão</button>
        </div>
        {taxaConversao !== null && (
          <div className="conversao-magica-result">
            <h2>Taxa de Conversão: {taxaConversao}%</h2>
            <div className="conversao-magica-recomendacao">{recomendacao}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConversaoMagica;
