import React, { useEffect } from 'react';
import './headlines.css'; // Alterado para o novo arquivo CSS
import logo from '../../imagens/logo_menu_login.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { isAuthenticated, logout } from '../../auth/auth';
import ChatHeadlines from './ChatHeadlines'; // Importando o Chat de Headlines

const Headlines = () => {
  const userEmail = localStorage.getItem('userEmail');
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login'); // Redireciona o usuário não autenticado para a tela de login
    }
  }, [navigate]);

  return (
    <div className="headlines-container"> {/* Classe atualizada */}
      <div className="headlines-header"> {/* Classe atualizada */}
        <img src={logo} alt="Logo" className="headlines-logo" /> {/* Classe atualizada */}
        <div className="headlines-user-info"> {/* Classe atualizada */}
          <span>{userEmail}</span>
          <button className="headlines-button" onClick={logout}>Sair</button> {/* Classe atualizada */}
        </div>
      </div>
      <div className="headlines-content"> {/* Classe atualizada */}
        <h1 className="headlines-title-gradient">Criação de Headlines Poderosas</h1> {/* Classe atualizada */}
        <p className="headlines-description"> {/* Classe atualizada */}
          ✍️ <strong>Crie Headlines Impactantes para Seus Anúncios!</strong> <br /><br />
          Nesta página, você vai utilizar nossa Inteligência Artificial para criar <strong>headlines poderosas</strong> que chamam a atenção e aumentam as conversões dos seus anúncios. Com textos otimizados, suas campanhas serão mais impactantes e terão um maior alcance.<br /><br />
          💡 <strong>Garanta que suas headlines sejam irresistíveis</strong> e atraia o público certo. Nossa IA vai te ajudar a criar as melhores frases que vão fazer seus clientes quererem saber mais sobre o que você tem a oferecer.
        </p>

        <Link to="/ferramentas" className="headlines-button-menu" style={{ textDecoration: 'none' }}>
          <FontAwesomeIcon icon={faArrowLeft} /> Voltar para o Menu
        </Link>

        <br /> {/* Adiciona uma linha de espaçamento */}
        <br /> {/* Adiciona mais uma linha de espaçamento */}

        {/* Chat de Headlines renderizado abaixo do botão de voltar para o menu */}
        <ChatHeadlines /> {/* Chat atualizado */}
      </div>
    </div>
  );
};

export default Headlines;
