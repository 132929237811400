import React, { useEffect } from 'react';
import './Persona.css'; // Alterado para o novo arquivo CSS
import logo from '../../imagens/logo_menu_login.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { isAuthenticated, logout } from '../../auth/auth';
import ChatPersona from './ChatPersona'; // Importando o Chat de Persona

const Persona = () => {
  const userEmail = localStorage.getItem('userEmail');
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login'); // Redireciona o usuário não autenticado para a tela de login
    }
  }, [navigate]);

  return (
    <div className="persona-container"> {/* Classe atualizada */}
      <div className="persona-header"> {/* Classe atualizada */}
        <img src={logo} alt="Logo" className="persona-logo" /> {/* Classe atualizada */}
        <div className="persona-user-info"> {/* Classe atualizada */}
          <span>{userEmail}</span>
          <button className="persona-button" onClick={logout}>Sair</button> {/* Classe atualizada */}
        </div>
      </div>
      <div className="persona-content"> {/* Classe atualizada */}
        <h1 className="persona-title-gradient">Criação de Personas Poderosas</h1> {/* Classe atualizada */}
        <p className="persona-description"> {/* Classe atualizada */}
          👤 <strong>Desenvolva Personas Eficazes para Suas Campanhas!</strong> <br /><br />
          Nesta página, você vai utilizar nossa Inteligência Artificial para criar <strong>personas detalhadas e precisas</strong>, que ajudarão a guiar suas estratégias de marketing. Com uma persona bem definida, suas campanhas terão um foco mais claro, aumentando a <strong>eficácia na comunicação com seu público</strong>.<br /><br />
          🔍 <strong>Crie personas ideais</strong> que irão facilitar a segmentação e personalização das suas campanhas, tornando-as mais assertivas e eficazes.
        </p>

        <Link to="/ferramentas" className="persona-button-menu" style={{ textDecoration: 'none' }}>
          <FontAwesomeIcon icon={faArrowLeft} /> Voltar para o Menu
        </Link>

        <br /> {/* Adiciona uma linha de espaçamento */}
        <br /> {/* Adiciona mais uma linha de espaçamento */}

        {/* Chat de Persona renderizado abaixo do botão de voltar para o menu */}
        <ChatPersona /> {/* Chat atualizado */}
      </div>
    </div>
  );
};

export default Persona;
