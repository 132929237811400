import React, { useEffect } from 'react';
import './CriadorDeCriativo.css'; // Alterado para o novo arquivo CSS
import logo from '../../imagens/logo_menu_login.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { isAuthenticated, logout } from '../../auth/auth';
import ChatCriadorDeCriativo from './ChatCriadorDeCriativo'; // Importando o Chat de Criador de Criativo

const CriadorDeCriativo = () => {
  const userEmail = localStorage.getItem('userEmail');
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login'); // Redireciona o usuário não autenticado para a tela de login
    }
  }, [navigate]);

  return (
    <div className="criador-de-criativo-container"> {/* Classe atualizada */}
      <div className="criador-de-criativo-header"> {/* Classe atualizada */}
        <img src={logo} alt="Logo" className="criador-de-criativo-logo" /> {/* Classe atualizada */}
        <div className="criador-de-criativo-user-info"> {/* Classe atualizada */}
          <span>{userEmail}</span>
          <button className="criador-de-criativo-button" onClick={logout}>Sair</button> {/* Classe atualizada */}
        </div>
      </div>
      <div className="criador-de-criativo-content"> {/* Classe atualizada */}
        <h1 className="criador-de-criativo-title-gradient">Criação de Criativos Poderosos</h1> {/* Classe atualizada */}
        <p className="criador-de-criativo-description"> {/* Classe atualizada */}
          🎨 <strong>Crie Criativos Impactantes para Suas Campanhas!</strong> <br /><br />
          Nesta página, você vai utilizar nossa Inteligência Artificial para criar <strong>criativos visuais otimizados</strong> que irão destacar seus produtos e aumentar suas conversões. Com a ajuda da nossa IA, você pode gerar criativos que chamam a atenção do público e <strong>potencializam o sucesso das suas campanhas</strong>.<br /><br />
          🚀 <strong>Deixe nossa IA criar os melhores criativos</strong> para que você se destaque no mercado e alcance mais clientes.
        </p>

        <Link to="/ferramentas" className="criador-de-criativo-button-menu" style={{ textDecoration: 'none' }}>
          <FontAwesomeIcon icon={faArrowLeft} /> Voltar para o Menu
        </Link>

        <br /> {/* Adiciona uma linha de espaçamento */}
        <br /> {/* Adiciona mais uma linha de espaçamento */}

        {/* Chat de Criador de Criativo renderizado abaixo do botão de voltar para o menu */}
        <ChatCriadorDeCriativo /> {/* Chat atualizado */}
      </div>
    </div>
  );
};

export default CriadorDeCriativo;
